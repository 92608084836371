import * as React from 'react'
import {  Link } from 'gatsby'
import Layout from '../../components/layout'
import '../../styles/global.css' 
import {Helmet} from "react-helmet";


export default function Fermanagh() {
  return (
    <Layout pageTitle="Fermanagh Futures">

      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>Fermanagh Futures - USI Projects</title>
        <meta name="description" content="Fermanagh Futures is an ambitious approach to tourism reinvention in Fermanagh."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>
      
         <section class="text-gray-700 body-font heading-container-fermanagh">
         <div class="relative mx-auto h-full px-5 md:px-16 py-24 items-center">
          <section class="self-end">
              <div class="absolute bottom-10">
                <h3 class="text-5xl lg:text-8xl font-heading leading-tight text-white pt-16">Fermanagh</h3>
                <p class="text-white text-xl md:text-2xl lg:mt-0"><span class="font-bold">Client: </span>
                Fermanagh Tourism</p>
              </div>
          </section>
        </div>
      </section>





      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 pt-12 lg:pt-24 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap">
              <div class="md:w-3/4 py-4 md:px-32 mb-4 md:mb-0">
                <h3 data-scroll data-scroll-speed="1" class="text-6xl font-heading text-blue-900">OVERVIEW</h3>
                <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  class="my-4 text-black leading-relaxed text-xl md:text-2xl lg:text-3xl ">
                  Fermanagh Futures is an ambitious approach to tourism reinvention in Fermanagh. The aim of this project was 
                  to understand what we are doing well within Fermanagh tourism, what we could improve and what ideas we have 
                  for the future of tourism in Fermanagh to establish a roadmap for reinvention over the coming months and years.
                  The developed platform outlines key tourism assets that are within Fermanagh and what are we already developing. 
                  It then sets out a range of potential new projects that address the challenges for tourism identified in Fermanagh.</p>
              </div>
              <div class="md:w-1/4 py-4 md:px-8 mb-4 md:mb-0 md:mt-28">
                <p class="text-2xl font-bold uppercase">Fields of
                  Practice</p>
                <p class="my-4 text-gray-500 leading-relaxed mt-0">Communities<br/>
                  Resilience<br/>
                  Public Space<br/>
                  Culture<br/>
                  Regeneration<br/>
                  Tourism<br/>
                  Economy<br/>
                  Technology<br/>
                </p>
                <p class="text-2xl font-bold uppercase mt-8">Services</p>
                <p class="my-4 text-gray-500 leading-relaxed mt-0">
                  Engagement</p>
                  <p class="text-2xl font-bold uppercase  mt-8">Visit</p>
                  <p class="my-4 text-gray-500 leading-relaxed mt-0"><a target="_blank" href='https://www.fermanaghfutures.com'>fermanagh<br/>futures.com</a></p>
              </div>
            </div>
          </section>
        </div>
      </section>

      <div class="md:p-16 md:m-16">
        <video className="mx-auto" controls src="https://player.vimeo.com/external/543248916.hd.mp4?s=d6f6a8e2b0d1d977d8c20d5031729f077a8b724f&profile_id=175">
        </video>
      </div>

      <img class="w-full" src="/img/projects/fermanagh-lake.webp"/>

      <div class="items-center py-32 bg-blue-100">
        <h2 class="text-center font-bold text-xl md:text-2xl lg:text-3xl ">How might we improve the visitor<br/> experience in Fermanagh….</h2>
        <div class="items-center max-w-full md:grid grid-cols-3 py-4 md:pl-16 md:pr-16">
          <img className="mx-auto" src="/img/projects/orange.svg"/>
          <img className="mx-auto" src="/img/projects/yellow-fish.svg"/>
          <img className="mx-auto" src="/img/projects/blue-box.svg"/>
        </div>
      </div>

      <div class="px-5 md:px-16 py-5 md:py-16 md:m-16">
        <div class="w-3/6 py-8">
          <h1 class="text-2xl md:text-3xl lg:text-4xl font-heading text-blue-900">WHAT IF</h1>
          <h1 class="text-3xl md:text-4xl lg:text-6xl font-heading text-blue-900">WE BROUGHT THE LEGENDS OF THE LAKE TO LIFE?</h1>
        </div>
        <img class="w-full" src="/img/projects/fermanagh-cave-tree.webp"/>
        <div class="flex py-12 text-lg md:text-xl lg:text-4xl w-6/6">
          <div class="w-3/6">
            <h3 class="font-bold  py-4">Connecting...</h3>
          </div>
          <div class="pl-8 md:pl-36 w-3/6">
            <h3 class="p-4 mb-2  font-bold bg-blue-100 rounded">To the water</h3>
            <h3 class="p-4 mb-2  font-bold bg-yellow-200 rounded">Around the water</h3>
            <h3 class="p-4 mb-2  font-bold bg-yellow-400 rounded">To our island town</h3>
            <h3 class="p-4 mb-2  font-bold bg-green-100 rounded">To our story</h3>
          </div>
        </div>
      </div>

      <img class="w-full" src="../img/projects/fermanagh-final.png"></img>

      <div class="bg-gray-200 py-16">
        <div class="text-center">
          <h3
            class="mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-5xl sm:leading-10 font-heading text-blue-900">
            Explore more work
          </h3>
          <p class="mt-4 max-w-2xl text-xl md:text-2xl  leading-7 text-black mx-12 md:mx-auto pt-6">
            Want to see more? Take a look at the way we’re responding to changes in the way we live, work and
            play.
          </p>
        </div>

        <div class="max-w-screen grid grid-cols-1 md:grid-cols-2 text-center">
          <div class="">
            <Link to="../../projects" class="carrow cta font-heading flipped text-gray-500 text-sm">All Projects
              <span className='hidden sm:block'></span>
            </Link>
          </div>
          <div class="">
            <Link to="../amtce" class="carrow cta font-heading text-gray-600">Next Project
              <span className='hidden sm:block'></span>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
